import React, { useState, useRef } from 'react'
import ReactModal from 'react-modal'
import styles from './VideoModal.module.scss'
import PlayButton from './PlayButton'
import Duration from './Duration'
import cn from 'classnames'
import { iOS } from '../puppyProfile/lib/utils'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(() => import('react-player'))

type VideoModalProps = {
  isOpen: boolean
  close: () => void
  video: any
}

export const VideoModal = React.memo(
  ({ isOpen, close, video }: VideoModalProps) => {
    if (typeof window !== 'undefined') {
      ReactModal.setAppElement('body')
    }

    const [showOverlay, setShowOverlay] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [played, setPlayed] = useState(0)
    const [duration, setDuration] = useState(0)
    const playerRef = useRef(null)
    const isIOS = iOS()

    const handlePlayPause = (e) => {
      e.stopPropagation()
      setShowOverlay(false)
      setPlaying(!playing)
    }

    const handlePause = () => {
      setPlaying(false)
    }

    const handleProgress = (state) => {
      setPlayed(state.played)
    }

    const handleEnded = () => {
      setShowOverlay(true)
    }

    const handleDuration = (duration) => {
      setDuration(duration)
    }
    /* eslint-disable */
    const isMobileLandscape =
      typeof screen !== 'undefined'
        ? screen?.availHeight < screen?.availWidth && screen?.availWidth < 900
        : true

    return (
      <ReactModal
        isOpen={isOpen}
        overlayClassName={styles.modalOverlay}
        className={cn({
          [styles.modal]: true,
          [styles.horizontalModal]: isMobileLandscape || isIOS,
        })}
        onAfterOpen={() => {
          setPlaying(true)
          if (window?.LiveChatWidget) {
            window.LiveChatWidget.call('hide')
          }
        }}
        shouldCloseOnOverlayClick
        onRequestClose={close}
        onAfterClose={() => {
          setPlayed(0)
          setPlaying(false)
        }}
      >
        <div className={styles.closeButton} onClick={close}>
          <img src="/close_modal.svg" />
        </div>
        <div
          className={cn({
            [styles.content]: true,
            [styles.horizontalContent]: isMobileLandscape || isIOS,
          })}
        >
          <div
            className={cn({
              [styles['player-wrapper']]: true,
              [styles.horizontalPlayerWrapper]: isMobileLandscape || isIOS,
            })}
            onClick={(e) => handlePlayPause(e)}
          >
            <ReactPlayer
              ref={playerRef}
              width="100%"
              height="100%"
              url={
                video?.high?.includes('http')
                  ? video?.high
                  : `https://fapvideo-development.s3.us-east-1.amazonaws.com/${video?.high}`
              }
              playing={playing}
              controls={false}
              onClickPreview={(e) => handlePlayPause(e)}
              onPause={handlePause}
              onEnded={handleEnded}
              onProgress={handleProgress}
              onDuration={handleDuration}
              progressInterval={0.5}
            />
          </div>
          {((!playing && played > 0) || showOverlay) && (
            <div className={styles['play-button-overlay']}>
              <PlayButton onClick={(e) => handlePlayPause(e)} />
            </div>
          )}
          {!isIOS && (
            <div className={styles['player-controls']}>
              <PlayButton
                onClick={(e) => handlePlayPause(e)}
                height={30}
                width={30}
                isPlaying={playing}
              />
              <progress max={1} value={played} />
              <Duration
                seconds={duration * played}
                className={styles.duration}
              />
            </div>
          )}
        </div>
      </ReactModal>
    )
  },
)
