import React from 'react'

type DurationProps = {
  className?: React.HTMLAttributes<HTMLTimeElement>['className']
  seconds: number
  total?: number
}

export default function Duration({ className, seconds, total }: DurationProps) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
      {!!total && ` / ${format(total)}`}
    </time>
  )
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${pad(mm)}:${ss}`
}

function pad(string) {
  return ('0' + string).slice(-2)
}
